import * as React from 'react';
import { Helmet } from 'react-helmet';

import { ContactComponent } from '../components/contact/contact.component';
import { MainLayout } from '../layouts/MainLayout';
import { Seo } from '../components/shared/Seo/Seo';
import { ContentPageWrapper } from '../layouts/page-layouts/content-page';
import { Wrapper } from '../layouts/page-layouts/wrapper';
import { PageHeadingMain, PageHeading2, PageHeading3 } from '../components/shared/PageHeaders';
import { PageSection } from '../layouts/page-layouts/page-section';

interface ContactPageProps { }

export default class extends React.Component<ContactPageProps, any> {
    constructor(props: ContactPageProps) {
        super(props);
    }

    public componentDidMount() {
        // this.mountWebflowJs();
    }

    private mountWebflowJs() {
        const scriptJq = document.createElement("script");
        scriptJq.src = `https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.4.1.min.220afd743d.js`;
        //scriptJq.integrity = 'sha256-CSXorXvZcTkaix6Yvo6HppcZGetbYMGWSFlBw8HfCJo=';
    
    
        const script = document.createElement("script");
        script.src = `js/webflow.js`;
    
    
    
    
        document.body.appendChild(scriptJq);
        document.body.appendChild(script);
      }

    public render() {
        const pageTitle = `Contact Us | Nuvious`;

        return (
            <MainLayout>
                <Seo path="/contact" />
                <Helmet>
                    <title>{pageTitle}</title>
                </Helmet>


                <div className="section">
                    <div className="container">
                        <div className="w-layout-grid contact-form-grid">
                            <div id="w-node-509be705858e-cb1fb644" className="contact-form-wrap">
                                <div className="contact-form-heading-wrap">
                                    <h2 className="contact-heading">Contact us</h2>
                                    <div className="paragraph-light">Get in touch with us, and maybe we can make something beautiful together. </div>
                                </div>
                                <div className="contact-form w-form">
                                    <form data-name="Get In Touch Form" name="wf-form-Get-In-Touch-Form" className="get-in-touch-form"><label htmlFor="name">Name</label><input type="text" className="text-field cc-contact-field w-input" maxLength={256} name="name" data-name="Name" placeholder="Enter your name" id="Name" /><label htmlFor="Email-2">Email Address</label><input type="email" className="text-field cc-contact-field w-input" maxLength={256} name="Email" data-name="Email" placeholder="Enter your email" id="Email" required /><label htmlFor="Message">Message</label><textarea id="Message" name="Message" placeholder="Hi there, I’m reaching out because I think we can work together…" maxLength={5000} data-name="Message" className="text-field cc-textarea cc-contact-field w-input" defaultValue={""} /><input type="submit" defaultValue="Submit" data-wait="Please wait..." className="button w-button" /></form>
                                    <div className="status-message cc-success-message w-form-done">
                                        <div>Thank you! Your submission has been received!</div>
                                    </div>
                                    <div className="status-message cc-error-message w-form-fail">
                                        <div>Oops! Something went wrong while submitting the form.</div>
                                    </div>
                                </div>
                            </div>
                            <div id="w-node-509be70585a6-cb1fb644">
                                <div className="details-wrap">
                                    <div className="label">OUR OFFICE</div>
                                    <div className="paragraph-light">9466 Georgia Ave. #66<br />Silver Spring, MD 20910 <br />United States</div>
                                </div>
                                <div className="details-wrap">
                                    <div className="label">WORKING HOURS</div>
                                    <div className="paragraph-light">7AM - 5PM, Mon to Fri</div>
                                </div>
                                <div className="details-wrap">
                                    <div className="label">CONTACT</div><a href="mailto:contact@business.com?subject=You've%20got%20mail!" className="contact-email-link">info@nuvious.com</a>
                                    <div className="paragraph-light">+1-855-656-NUVI (6884)</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </MainLayout>
        );
    }
}
